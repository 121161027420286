<template>
  <div>
    <Button
      class="group"
      :class="[
        {
          'pointer-events-none cursor-default text-neutral-300':
            !swiper.loopedSlides && swiper.isBeginning,
          'md:hover:text-purple': !swiper.isBeginning
        }
      ]"
      variant="unstyled"
      :is-disabled="swiper.isBeginning"
      :disabled="swiper.isBeginning"
      :title="$t('pagination.previous')"
      :icon="{ name: 'circle-chevron-left-general', color: 'white', className: buttonClass }"
      @click="swiper.slidePrev()"
    />
    <Button
      class="ml-[10px] md:ml-[15px]"
      :class="[
        {
          'pointer-events-none cursor-default text-neutral-300':
            !swiper.loopedSlides && swiper.isEnd,
          'md:hover:text-purple': !swiper.isEnd
        }
      ]"
      variant="unstyled"
      :is-disabled="swiper.isEnd"
      :disabled="swiper.isEnd"
      :title="$t('pagination.next')"
      :icon="{ name: 'circle-chevron-right-general', color: 'white', className: buttonClass }"
      @click="swiper.slideNext()"
    />
  </div>
</template>

<script setup lang="ts">
import { useSwiper } from 'swiper/vue'

const swiper = useSwiper()

defineProps({
  buttonClass: {
    type: String,
    default: 'w-10px h-10px'
  }
})
</script>
